<template>
  <AppProvider>
    <router-view :key="$route.fullPath"></router-view>
  </AppProvider>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>

</style>
