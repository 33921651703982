import { createApp } from 'vue'
import PolarisVue from '@ownego/polaris-vue'
import '@ownego/polaris-vue/dist/style.css'

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import router from "@/routes";
import App from './App.vue'
import axios from 'axios';
import InfiniteScrollDirective from './directives/v-infinite-scroll';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

let token = localStorage.getItem('CURRENT_TOKEN')

if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

axios.get('api/auth-check').catch(() => {
    window.localStorage.removeItem('CURRENT_USER')
    window.localStorage.removeItem('CURRENT_TOKEN')

    delete axios.defaults.headers.common['Authorization'];
})

window.pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'reverb',
    key: process.env.VUE_APP_REVERB_APP_KEY, // Your Reverb app key
    wsHost: window.location.hostname, // The host where Reverb is running
    wsPort: process.env.VUE_APP_REVERB_PORT ?? 80, // The port where Reverb is running
    wssPort: process.env.VUE_APP_REVERB_PORT ?? 443, // The port where Reverb is running
    enabledTransports: ['ws', 'wss'], // Enable websockets
    forceTLS: (process.env.VUE_APP_REVERB_SCHEME ?? 'https') === 'https',
    disableStats: true,
    auth: {
        headers: {
            Authorization: 'Bearer ' + token
        },
    },
});



const app = createApp(App)

app.directive('infinite-scroll', InfiniteScrollDirective);

app.use(PolarisVue).use(router).mount('#app')
